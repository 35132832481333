.switch {
  position: relative;
  width: auto;

  [data-icon] {
    --gutter: 2px;
    --knob-size: var(--sizing-4);

    /**
      Checkbox icon are positioned absolutely because we need to align the elements along the baseline
      but icon takes more space than the text content.
    */
    position: absolute;
    width: var(--sizing-8);
    height: var(--sizing-5);
    background-color: var(--color-bd-neutral);
    border-radius: var(--knob-size);
    color: var(--color-bg);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    flex-shrink: 0;
  }

  [data-icon]::after {
    content: "";
    height: var(--knob-size);
    width: var(--knob-size);
    transition: all 0.2s ease;
    position: absolute;
    left: var(--gutter);
    border-radius: var(--knob-size);
    background-color: currentColor;
  }

  &[data-hovered]:not([data-disabled]) [data-icon] {
    background-color: var(--color-bd-neutral-hover);
  }

  /**
  * ----------------------------------------------------------------------------
  * LABEL POSITION
  *-----------------------------------------------------------------------------
  */
  &[data-label-position="end"] {
    padding-inline-start: calc(var(--sizing-8) + var(--inner-spacing-2));
    justify-content: flex-end;
  }

  &[data-label-position="end"] [data-icon] {
    left: 0;
  }

  &[data-label-position="end"] .text {
    margin-inline-start: auto;
  }

  &[data-label-position="start"] {
    padding-inline-end: calc(var(--sizing-8) + var(--inner-spacing-2));
  }

  &[data-label-position="start"] [data-icon] {
    right: 0;
  }

  &[data-label-position="start"] .text {
    margin-inline-end: auto;
  }

  /**
 * ----------------------------------------------------------------------------
 * CHECKED  - BUT NOT DISABLED
 *-----------------------------------------------------------------------------
 */
  &[data-state="checked"] [data-icon] {
    background-color: var(--color-bg-accent);
    color: var(--color-bg);
  }

  &[data-hovered][data-state="checked"]:not([data-disabled]) [data-icon] {
    background-color: var(--color-bg-accent-hover);
    color: var(--color-bg);
  }

  &[data-state="checked"] [data-icon]::after {
    left: calc(100% - var(--knob-size) - var(--gutter));
  }

  /**
  * ----------------------------------------------------------------------------
  * FOCUS
  *-----------------------------------------------------------------------------
  */
  &[data-focused] [data-icon] {
    box-shadow:
      0 0 0 2px var(--color-bg),
      0 0 0 4px var(--color-bd-focus);
  }
}
